import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const debug = false;

function ConvertPlanTypes(plan) {
  const keyMapping = {
    'Adult': 0,
    'Child': 1,
    'Perio': 2,
  };
  //convert percentBreakage
  for (const key in plan.percentBreakage) {
    if(Object.keys(keyMapping).includes(key)) {
      plan.percentBreakage[keyMapping[key]] = plan.percentBreakage[key]
      delete plan.percentBreakage[key]
    }
  }
  //convert benefit PlanTypes
  for (const benefit of plan.benefits) {
      benefit.planTypes = benefit.planTypes.map(type => {
        // Check if the type exists in keyMapping and return the corresponding number
        if (Object.keys(keyMapping).includes(type)) {
            return keyMapping[type];
        }
        // If not found, return the type as is (or handle it however you see fit)
        return type;
      });
      //convert benefit prices
      for (const key in benefit.prices) {
        if(Object.keys(keyMapping).includes(key)) {
          benefit.prices[keyMapping[key]] = benefit.prices[key]
          delete benefit.prices[key]
        }
      }
  }
  //convert plan PlanTypes
  for (const currentPlan of plan.plans) {
    if(typeof currentPlan.planType === 'string'){
      currentPlan.planType = keyMapping[currentPlan.planType];
    }
  }
}

export default new Vuex.Store({
  state: {

    uneditedState: null,

    id: 0,

    isFreshIntake: false,

    practiceName: '',
    practiceEmail: '',
    practiceZip: '',

    includeDMFee: false,
    includeProcessingFee: false,
    isPediatric: false,

    rewardsPointsPerVisit: 25,
    reimbursementPercentage: 50,
    includePointsInPlanPrice: false,

    enrollmentFeeFirstVisit: 79,

    planTypes: [
      "Adult", "Child", "Perio"
    ],

    percentBreakage: {
      0: 0,
      1: 0,
      2: 0,
    },
    dentalMenuFee: 4,

    ucrsSet: false,
    adultPlanSet: false,
    childPlanSet: false,

    existChanges: false,

    note: "",

    practiceWebsite: "",
    landingPage: "",
    practiceAddress: "",
    addressLineTwo: "",
    practicePhone: "",
    includeBrochures: false,
    brochureStyle: "",
    brochureColors: "",

    practiceCity: "",
    practiceState: "",

    allStates: ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'],

    benefits: [
      {
        id: 0,
        planTypes: [
          0,
        ],
        name: "Adult Cleaning",
        technicalName: "Adult Prophylaxis",
        code: "D1110",
        UCR: null,
        prices: {
          0: 91,
        },
        frequencies: {
          //frequency of -1 indicates "with plan"
          0: -1,
        }
      },
      {
        id: 1,
        planTypes: [
          1,
        ],
        name: "Child Cleaning",
        technicalName: "Child Prophylaxis",
        code: "D1120",
        UCR: null,
        prices: {
          1: 67,
        },
        frequencies: {
          1: -1,
        }
      },
      {
        id: 2,
        planTypes: [
          2,
        ],
        name: "Perio Maintenance",
        technicalName: "Periodontal Maintenance",
        code: "D4910",
        UCR: null,
        prices: {
          2: 105,
        },
        frequencies: {
          2: -1,
        }
      },
      {
        id: 3,
        planTypes: [
          0, 1, 2
        ],
        name: "Periodic Exam",
        technicalName: "Periodic Oral Evaluation",
        code: "D0120",
        UCR: null,
        prices: {
          0: 52,
          1: 52,
          2: 52,
        },
        frequencies: {
          0: -1,
          1: -1,
          2: -1,
        }
      },
      {
        id: 4,
        planTypes: [
          0, 1, 2
        ],
        name: "4 Bitewing X-Rays",
        technicalName: "4 Bitewing X-Rays",
        code: "D0274",
        UCR: null,
        prices: {
          0: 64,
          1: 64,
          2: 64,
        },
        frequencies: {
          0: 12,
          1: 12,
          2: 12,
        }
      },
      {
        id: 5,
        planTypes: [
          0, 2,
        ],
        name: "First PA",
        technicalName: "",
        code: "D0220",
        UCR: null,
        prices: {
          0: 27,
          2: 27
        },
        frequencies: {
          0: 12,
          2: 12,
        }
      },
      {
        id: 6,
        planTypes: [
          0, 2,
        ],
        name: "Additional PA",
        technicalName: "",
        code: "D0230",
        UCR: null,
        prices: {
          0: 27,
          2: 27
        },
        frequencies: {
          0: 12,
          2: 12
        }
      },
      {
        id: 7,
        planTypes: [
          
        ],
        name: "Panoramic X-Ray",
        technicalName: "",
        code: "D0330",
        UCR: null,
        prices: {},
        frequencies: {}
      },
      {
        id: 8,
        planTypes: [
          
        ],
        name: "3D Imaging",
        technicalName: "",
        code: "D0366",
        UCR: null,
        prices: {},
        frequencies: {}
      },
      {
        id: 9,
        planTypes: [
          
        ],
        name: "Comprehensive Exam",
        technicalName: "",
        code: "D0150",
        UCR: null,
        prices: {},
        frequencies: {}
      },
      {
        id: 10,
        planTypes: [
          "Add-On"
        ],
        name: "Fluoride Varnish",
        technicalName: "",
        code: "D1206",
        UCR: null,
        prices: {},
        frequencies: {}
      },
      {
        id: 11,
        planTypes: [
          "Add-On"
        ],
        name: "Fluoride Treatment",
        technicalName: "",
        code: "D1208",
        UCR: null,
        prices: {},
        frequencies: {}
      },
      {
        id: 12,
        planTypes: [
          "Add-On"
        ],
        name: "Limited Exam",
        technicalName: "",
        code: "D0140",
        UCR: null,
        prices: {},
        frequencies: {}
      },
      {
        id: 13,
        planTypes: [
          "Add-On"
        ],
        name: "Laughing Gas- Nitrous",
        technicalName: "",
        code: "D9230",
        UCR: null,
        prices: {},
        frequencies: {}
      },
      {
        id: 14,
        planTypes: [
          
        ],
        name: "In-Office Whitening",
        technicalName: "",
        code: "D9972",
        UCR: null,
        prices: {},
        frequencies: {}
      },
      {
        id: 15,
        planTypes: [
          
        ],
        name: "Full-Mouth X-Rays",
        technicalName: "",
        code: "D0210",
        UCR: null,
        prices: {},
        frequencies: {}
      },
      {
        id: 16,
        planTypes: [
          
        ],
        name: "2 Bitewing X-Rays",
        technicalName: "2 Bitewing X-Rays",
        code: "D0272",
        UCR: null,
        prices: {},
        frequencies: {}
      },
      //REMEMBER! When adding new benefits, be sure to set a default UCR value inside of setUCRsDemo() so that the demo still runs smoothly.
      //ALSO REMEMBER to change defaultBenefitsLength to ensure that newly-added benefits can't have IDs equal to or below the starting IDs (very important for the changelog)
    ],

    defaultBenefitsLength: 16,

    essentialCodes: ['D0120', 'D0274', 'D1110', 'D1120', 'D4910'],

    annualCodes: ['D0272', 'D0274', 'D9972', 'D0220', 'D0230'],

    plans: [
      {
        id: 0,
        planType: 0,
        planName: "Adult Essential",
        frequency: 6,
        price: 30,
        annualPrice: 360,
        isActive: true,
        includeStripeFee: true,
        includeDMFee: true,
        monthlyPriceAltered: false,
        annualPriceAltered: false,
      },
      {
        id: 1,
        planType: 0,
        planName: "Adult Basic",
        frequency: 12,
        price: 30,
        annualPrice: 360,
        isActive: true,
        includeStripeFee: true,
        includeDMFee: true,
        monthlyPriceAltered: false,
        annualPriceAltered: false,
      },
      {
        id: 2,
        planType: 1,
        planName: "Child Premier",
        frequency: 4,
        price: 30,
        annualPrice: 360,
        isActive: true,
        includeStripeFee: true,
        includeDMFee: true,
        monthlyPriceAltered: false,
        annualPriceAltered: false,
      },
      {
        id: 3,
        planType: 1,
        planName: "Child Essential",
        frequency: 6,
        price: 30,
        annualPrice: 360,
        isActive: true,
        includeStripeFee: true,
        includeDMFee: true,
        monthlyPriceAltered: false,
        annualPriceAltered: false,
      },
      {
        id: 4,
        planType: 2,
        planName: "Perio Plus",
        frequency: 3,
        price: 30,
        annualPrice: 360,
        isActive: true,
        includeStripeFee: true,
        includeDMFee: true,
        monthlyPriceAltered: false,
        annualPriceAltered: false,
      },
      {
        id: 5,
        planType: 2,
        planName: "Perio Essential",
        frequency: 4,
        price: 30,
        annualPrice: 360,
        isActive: true,
        includeStripeFee: true,
        includeDMFee: true,
        monthlyPriceAltered: false,
        annualPriceAltered: false,
      },
    ],

    addOns: [
      {
        id: 0,
        title: "Fluoride Treatments",
        description: "Protect your teeth with an application of fluoride varnish at each visit.",
        price: 3,
        isActive: true,
        benefits: [10],
        shouldCreateNew: false
      },
      {
        id: 1,
        title: "Worry-Free",
        description: "Emergency Exams & X-rays (up to 2 per year) are included with your plan benefits",
        price: 6,
        isActive: true,
        benefits: [12],
        shouldCreateNew: false
      },
      {
        id: 2,
        title: "Premier Upgrade",
        description: "Bundle Fluoride & Worry-Free together & save",
        price: 8,
        isActive: true,
        benefits: [10, 12],
        shouldCreateNew: false
      },
      {
        id: 3,
        title: "Create Your Own",
        description: "Your description goes here",
        price: 0,
        isActive: false,
        benefits: [],
        shouldCreateNew: true
      },
    ],

    monthlyDiscounts: [
      {id: 0, title: "Additional Member", description: "For Family Plans", dollarsOff: 2, isActive: true, isMultiplePatient: true, isSenior: false},
      {id: 1, title: "Senior Citizens", description: "Age 65+", dollarsOff: 2, isActive: true, isMultiplePatient: false, isSenior: true},
      {id: 2, title: "Military", description: "Active Duty and Veterans", dollarsOff: 3, isActive: true, isMultiplePatient: false, isSenior: false},
    ],

    treatmentDiscounts: [
      {id: 0, title:"Restorative Work", description: "Fillings, Crowns, Etc.", percentOff: 25, isActive: true, isRestorative: true, isPerio: false},
      {id: 1, title:"Periodontal Treatment", description: "Scaling, Debridement", percentOff: 35, isActive: true, isRestorative: false, isPerio: true,}
    ],
  },
  mutations: {
    SET_IS_FRESH_INTAKE(state, val) {
      //console.log('setting isFreshIntake in store');
      state.isFreshIntake = val;
    },
    
    SET_PRACTICE_NAME(state, name) {
      state.existChanges = true;
      state.practiceName = name;
      if(debug) {console.log("Name updated in store- value = ", state.practiceName)}
    },

    SET_PRACTICE_EMAIL(state, email) {
      state.existChanges = true;
      state.practiceEmail = email;
      if(debug) {console.log("Name updated in store- value = ", state.practiceEmail)}
    },
    
    SET_REWARDS_POINTS(state, num) {
      state.existChanges = true;
      state.rewardsPointsPerVisit = num;
      if(debug) {console.log("Rewards Points updated in store- value = ", state.rewardsPointsPerVisit)}
    },

    SET_REIMBURSEMENT_PERCENTAGE(state, percent) {
      state.existChanges = true;
      state.reimbursementPercentage = percent;
      if(debug) {console.log("Reimbursement Percentage updated in store- value = ", state.reimbursementPercentage)}
    },

    TOGGLE_POINTS_INCLUDED_IN_PRICE(state) {
      state.existChanges = true;
      state.includePointsInPlanPrice = !state.includePointsInPlanPrice;
    },

    SET_DM_FEE(state, fee) {
      state.dentalMenuFee = fee;
    },

    TOGGLE_INCLUDE_DM_FEE(state) {
      state.existChanges = true;
      state.includeDMFee = !state.includeDMFee;
    },

    TOGGLE_INCLUDE_PROCESSING_FEE(state) {
      state.existChanges = true;
      state.includeProcessingFee = !state.includeProcessingFee;
    },

    TOGGLE_IS_PEDIATRIC(state) {
      state.existChanges = true;
      state.isPediatric = !state.isPediatric;
    },

    CONVERT_TO_PEDIATRIC(state) {
      //This should only be used in Intake- it could override some user selections if used after intake
      state.existChanges = true;
      state.isPediatric = true;
      state.planTypes = ["13+", "12 & Under", "Other"];
      state.plans[4].frequency = 6;
      state.plans[5].frequency = 12;
      state.plans[0].planName = "13+ Essential";
      state.plans[1].planName = "13+ Basic";
      state.plans[2].planName = "12 & Under Premier";
      state.plans[3].planName = "12 & Under Essential";
      state.plans[4].planName = "Other Essential";
      state.plans[5].planName = "Other Basic";

      let perioMaintenance = state.benefits[2];
      perioMaintenance.planTypes = [];
      perioMaintenance.prices = {};
      perioMaintenance.frequencies = {};
      state.benefits[2] = perioMaintenance;
    },

    SET_ENROLLMENT_FEE(state, fee) {
      state.existChanges = true;
      state.enrollmentFeeFirstVisit = fee;
      if(debug) {console.log("Enrollment Fee updated in store- value = ", state.enrollmentFeeFirstVisit)}
    },

    UPDATE_PERCENT_BREAKAGE_STORE(state, breakage) {
      state.existChanges = true;
      state.percentBreakage[breakage["type"]] = breakage["percent"];
      if(state.adultPlanSet === false) {
        for (let key in state.percentBreakage) {
          if (Object.prototype.hasOwnProperty.call(state.percentBreakage, key)) {
            state.percentBreakage[key] = breakage["percent"];
          }
        }
      }
      if(debug) {console.log("Percent breakage updated to ", state.percentBreakage, "% in store")}
    },

    UPDATE_BENEFIT_BY_ID(state, inputBenefit) {
      if(debug){console.log("Entering updateByID")}
      const index = state.benefits.findIndex(benefit => benefit.id === inputBenefit.id);
      
      if(inputBenefit.id === 3 && state.adultPlanSet === false) {
        if(debug) {console.log("Setting child & perio exam prices to adult exam prices in store")}
        inputBenefit.prices[1] = inputBenefit.prices[0];
        inputBenefit.prices[2] = inputBenefit.prices[0];
      }
      else if(inputBenefit.id === 4 && state.adultPlanSet === false) {
        if(debug) {console.log("Setting child & perio X-Ray prices to adult X-Ray prices in store")}
        inputBenefit.prices[1] = inputBenefit.prices[0];
        inputBenefit.prices["2"] = inputBenefit.prices[0];
      }
      if(index !== -1) {
        state.existChanges = true;
        Vue.set(state.benefits, index, inputBenefit);
        if(debug) {
          console.log("Update complete. Benefits in Store = ", state.benefits)
        }
      }
    },

    ADD_BENEFIT(state, inputBenefit) {
      state.existChanges = true;
      state.benefits.push(inputBenefit);
      if(debug) {console.log("Benefit added in store = ", inputBenefit)}
    },

    REMOVE_BENEFIT(state, inputBenefit) {
      state.existChanges = true;
      state.benefits = state.benefits.filter(benefit => benefit.id !== inputBenefit.id)
      state.addOns.forEach((addOn) => {
        addOn.benefits = addOn.benefits.filter(benefit => benefit !== inputBenefit.id);
      })
    },

    UPDATE_PLAN(state, newPlan) {
      //console.log("store.UpdatePlan called");
      state.existChanges = true;
      //if(debug) {console.log("New plan = ", newPlan)}
      state.plans.forEach((plan, index) => {
        if(plan.id === newPlan.id && plan !== newPlan) {
          state.existChanges = true;
          state.plans[index] = newPlan
          if(debug){console.log("Updated plan in store = ", state.plans[index]);}
          if(debug) {console.log("Plans updated in store: Plans = ", state.plans)}
        }
      });
    },

    REMOVE_PLAN(state, id) {
      state.existChanges = true;
      state.plans.forEach((plan, index) => {
        if(plan.id === id) {
          state.plans.splice(index, 1)
        }
      });
      if(debug) {console.log("Removed plan in store - plans = ", state.plans)}
    },

    SET_MONTHLY_DISCOUNTS(state, discounts) {
      state.existChanges = true;
      state.monthlyDiscounts = discounts
      if(debug) {console.log("Updated monthly discounts in Store- discounts = ", state.monthlyDiscounts)}
    },

    UPDATE_MONTHLY_DISCOUNT_BY_ID(state, inputDiscount) {
      const index = state.monthlyDiscounts.findIndex(discount => discount.id === inputDiscount.id);
      if(index !== -1) {
        state.existChanges = true;
        state.monthlyDiscounts[index] = inputDiscount;
        if(debug) {console.log("Updated monthly discount with id ", index, ": new value = ", state.monthlyDiscounts[index]);}
      }
    },

    UPDATE_TREATMENT_DISCOUNT_BY_ID(state, inputDiscount) {
      const index = state.treatmentDiscounts.findIndex(discount => discount.id === inputDiscount.id);
      if(index !== -1) {
        state.existChanges = true;
        state.treatmentDiscounts[index] = inputDiscount;
        if(debug) {console.log("Updated treatment discount with id ", index, ": new value = ", state.treatmentDiscounts[index]);}
      }
    },

    SET_TREATMENT_DISCOUNTS(state, discounts) {
      state.existChanges = true;
      state.treatmentDiscounts = discounts
      if(debug) {console.log("Updated treatment discounts in Store- discounts = ", state.treatmentDiscounts)}
    },

    SET_ADD_ONS(state, addOns) {
      state.existChanges = true;
      state.addOns = addOns
      if(debug) {console.log("Updated add-ons in store. Add-ons: ", state.addOns)}
    },

    UPDATE_ADD_ON_BY_ID(state, inputAddOn) {
      const index = state.addOns.findIndex(addOn => addOn.id === inputAddOn.id);
      if(index !== -1) {
        state.existChanges = true;
        state.addOns[index] = inputAddOn;
      }
    },

    ADD_BENEFIT_TO_ADD_ON(state, payload) {
      state.existChanges = true;
      const { benefitId, addOnId } = payload;
      let index = state.addOns.findIndex(addOn => addOn.id === addOnId)
      state.addOns[index].benefits.push(benefitId)
    },

    REMOVE_BENEFIT_FROM_ADD_ON(state, payload) {
      state.existChanges = true;
      const { benefitId, addOnId } = payload;
      const addOnIndex = state.addOns.findIndex(addOn => addOn.id === addOnId);
      if (addOnIndex !== -1) {
          let benefitIndex = state.addOns[addOnIndex].benefits.findIndex(benefit => benefit === benefitId);
          if (benefitIndex !== -1) {
              state.addOns[addOnIndex].benefits.splice(benefitIndex, 1);
          }
      }
    },

    SET_UCRS_SET(state) {
      state.ucrsSet = true;
    },

    SET_ADULT_PLAN_SET(state) {
      if(debug) {console.log("Setting adultPlanSet to true in store")}
      state.adultPlanSet = true;
    },

    SET_CHILD_PLAN_SET(state) {
      if(debug) {console.log("Setting childPlanSet to true in store")}
      state.childPlanSet = true;
    },
    
    SET_UCRS_DEMO(state) {
      let ucrs = [130,90,150,70,90,40,40,160,180,130,60,60,110,120,250,220,50]
      state.benefits.forEach((benefit, index) => {
        state.benefits[index].UCR = ucrs[index]
      });
    },

    SET_UCRS_MAIN(state) {
      state.benefits[3].UCR = 50;
      state.benefits[4].UCR = 100;
      state.benefits[0].UCR = 150;
      state.benefits[1].UCR = 100;
      state.benefits[2].UCR = 200;
    },

    SET_BENEFIT_PRICES_AT_UCR(state) {
      console.log("Entering store.SET_BENEFIT_PRICES_AT_UCR")
      state.existChanges = true;
      // Assuming state.benefits is an array of objects
      for (let i = 0; i < state.benefits.length; i++) {
        let benefit = state.benefits[i]; // Access the benefit object using index
        for (let type in benefit.prices) {
          benefit.prices[type] = benefit.UCR;
        }
      }
    },

    SET_BENEFIT_PRICES_BELOW_UCR(state) {
      console.log("Entering store.SET_BENEFIT_PRICES_BELOW_UCR")
      state.existChanges = true;
      for (let i = 0; i < state.benefits.length; i++) {
        let benefit = state.benefits[i]; // Access the benefit object using index
        for (let type in benefit.prices) {
          if(benefit.prices[type] > benefit.UCR) {
            benefit.prices[type] = benefit.UCR;
          }
        }
      }
    },

    SET_NOTE(state, note) {
      state.existChanges = true;
      state.note = note;
    },

    SET_WEBSITE(state, site) {
      state.existChanges = true;
      state.practiceWebsite = site;
    },

    SET_LANDING_PAGE(state, landingPage) {
      state.existChanges = true;
      state.landingPage = landingPage;
    },

    SET_ADDRESS(state, address) {
      state.existChanges = true;
      state.practiceAddress = address;
    },

    SET_ADDRESS_LINE_TWO(state, address) {
      state.existChanges = true;
      state.addressLineTwo = address;
    },

    SET_PHONE_NUMBER(state, number) {
      state.existChanges = true;
      state.practicePhone = number;
    },

    SET_INCLUDE_BROCHURES(state) {
      state.includeBrochures = !state.includeBrochures;
    },

    SET_BROCHURE_STYLE(state, style) {
      state.existChanges = true;
      state.brochureStyle = style;
    },

    SET_BROCHURE_COLOR(state, color) {
      state.existChanges = true;
      state.brochureColors = color;
    },

    SET_PRACTICE_CITY(state, city) {
      state.existChanges = true;
      state.practiceCity = city;
      if(debug) {console.log("City updated in store- value = ", state.practiceCity)}
    },

    SET_PRACTICE_STATE(state, inputState) {
      state.existChanges = true;
      state.practiceState = inputState;
      if(debug) {console.log("State updated in store- value = ", state.practiceState)}
    },

    SET_PRACTICE_ZIP(state, zip) {
      state.existChanges = true;
      state.practiceZip = zip;
      if(debug) {console.log("Zip updated in store- value = ", state.practiceZip)}
    },

    SET_PLAN_TYPE_NAME(state, payload) {
      const { planTypeIndex, newName } = payload;
      state.planTypes[planTypeIndex] = newName;
      //console.log("Plan Type Name updated in store- index ", planTypeIndex, " updated to ", newName)
    },

    SET_UNEDITED_STATE_TO_CURRENT_STATE(state) {
      this.state.uneditedState = JSON.parse(JSON.stringify(state))
    },

    SET_EVERYTHING(state, plan) {
      ConvertPlanTypes(plan);

      state.id = plan.id;
      state.practiceName = plan.practiceName;
      state.practiceEmail = plan.practiceEmail;
      state.rewardsPointsPerVisit = plan.rewardsPointsPerVisit;
      state.reimbursementPercentage = plan.reimbursementPercentage;
      state.enrollmentFeeFirstVisit = plan.enrollmentFeeFirstVisit;
      state.planTypes = plan.planTypes;
      state.percentBreakage = plan.percentBreakage;
      state.adultPlanSet = plan.adultPlanSet;
      state.childPlanSet = plan.childPlanSet;
      state.benefits = plan.benefits;
      state.plans = plan.plans;
      state.addOns = plan.addOns;
      state.monthlyDiscounts = plan.monthlyDiscounts;
      state.treatmentDiscounts = plan.treatmentDiscounts;
      state.dentalMenuFee = plan.dentalMenuFee;

      //changes after adding checkboxes to treatment discounts to ensure that treatmentDiscounts show up in the plan overview
      if(plan.includePointsInPlanPrice) {
        state.includePointsInPlanPrice = true;
      }

      state.treatmentDiscounts.forEach(discount => {
        if (discount.isActive === undefined) {
          if(discount.percentOff > 0) {
            discount.isActive = true;
          }
          else {
            discount.isActive = false;
          }
      }});
      state.addOns.forEach(addOn => {
        if(addOn.benefits === undefined) {
          addOn.benefits = [];
        }
        addOn.benefits = addOn.benefits.filter(benefit => benefit !== null);
      });
      if(plan.isPediatric) {
        state.monthlyDiscounts[0].isSenior = false;
        state.monthlyDiscounts[0].isMultiplePatient = true;
        state.monthlyDiscounts[1].isSenior = false;
        state.monthlyDiscounts[1].isMultiplePatient = false;

        state.treatmentDiscounts[0].isRestorative = true;
        state.treatmentDiscounts[0].isPerio = false;
      }
      else {
        state.monthlyDiscounts[0].isSenior = false;
        state.monthlyDiscounts[0].isMultiplePatient = true;
        state.monthlyDiscounts[1].isSenior = true;
        state.monthlyDiscounts[1].isMultiplePatient = false;
        state.monthlyDiscounts[2].isSenior = false;
        state.monthlyDiscounts[2].isMultiplePatient = false;

        state.treatmentDiscounts[0].isRestorative = true;
        state.treatmentDiscounts[0].isPerio = false;
        state.treatmentDiscounts[1].isRestorative = false;
        state.treatmentDiscounts[1].isPerio = true;
      }
        
        //changes after changing DM fee & Stripe fee from per-plan settings to global settings
        if (plan.includeDMFee !== undefined) {
          state.includeDMFee = plan.includeDMFee;
          state.includeProcessingFee = plan.includeProcessingFee;
        }
        else if (plan.plans[0].includeDMFee !== undefined) {
          state.includeDMFee = plan.plans[0].includeDMFee;
          state.includeProcessingFee = plan.plans[0].includeStripeFee;
        }
        //changes after adding Notes
        if(plan.note !== undefined) {
          state.note = plan.note;
        }
        //changes after adding Contact Page
        if(plan.practiceWebsite !== undefined) {
          state.practiceWebsite = plan.practiceWebsite;
        }
        if (plan.landingPage !== undefined) {
          state.landingPage = plan.landingPage;
        }
      
        if (plan.practiceAddress !== undefined) {
          state.practiceAddress = plan.practiceAddress;
        }

        if(plan.addressLineTwo !== undefined) {
          state.addressLineTwo = plan.addressLineTwo;
        }
      
        if (plan.practicePhone !== undefined) {
          state.practicePhone = plan.practicePhone;
        }
      
        if (plan.includeBrochures !== undefined) {
          state.includeBrochures = plan.includeBrochures;
        }
      
        if (plan.brochureStyle !== undefined) {
          state.brochureStyle = plan.brochureStyle;
        }
      
        if (plan.brochureColors !== undefined) {
          state.brochureColors = plan.brochureColors;
        }

        if(plan.practiceCity !== undefined) {
          state.practiceCity = plan.practiceCity;
        }

        if(plan.practiceState !== undefined) {
          state.practiceState = plan.practiceState;
        }

        if(plan.practiceZip !== undefined) {
          state.practiceZip = plan.practiceZip;
        }

        if(plan.plans[3].planName === 'Adult Plan- 6 months') {
          state.plans[3].planName = 'Child Plan- 6 months';
        }

        plan.plans[0].name = "Adult Premier";
        plan.plans[1].name = "Adult Essential";
        plan.plans[2].name = "Child Premier";
        plan.plans[3].name = "Child Essential";
        plan.plans[4].name = "Perio Plus";
        plan.plans[5].name = "Perio Essential";

        if(plan.isPediatric !== undefined) {
          state.isPediatric = plan.isPediatric;
        }
        //console.log("isFreshIntake = ", plan.isFreshIntake)
        if(plan.isFreshIntake !== undefined) {
          state.isFreshIntake = plan.isFreshIntake;
        }

        switch(plan.brochureStyle) {
          case 'FAMILY':
            state.brochureStyle = "Family Photography"; break;
          case 'SWIRLS':
            state.brochureStyle = "Decorative Swirls"; break;
          case 'BLOCKS':
            state.brochureStyle = "Modern Blocks"; break;
          case 'ILLUSTRATED':
            state.brochureStyle = "Illustrated"; break;
        }

        switch(plan.brochureColors) {
          case 'BLUES':
            state.brochureColors = "Blues"; break;
          case 'SUMMER':
            state.brochureColors = "Summer"; break;
          case "TAHITI":
            state.brochureColors = "Tahiti"; break;
          case "EARTH":
            state.brochureColors = "Earth"; break;
          case "Royal":
            state.brochureColors = "Royal"; break;
        }

        if(plan.benefits[0].frequencies === undefined) {
          plan.benefits.forEach((benefit) => {
            benefit['frequencies'] = {};
            benefit.planTypes.forEach((planType) => {
              if(planType !== 'Add-On')
                if(benefit.isAnnual) {
                  benefit.frequencies[planType] = 12;
                }
                else {
                  benefit.frequencies[planType] = -1;
                }
            })
            delete benefit.isAnnual;
          })
        }
        this.state.uneditedState = JSON.parse(JSON.stringify(plan))
        state.existChanges = false;
    },
  },
  getters: {
    benefitsByType: state => (type) => {
      let result = state.benefits.filter(benefit => benefit.planTypes.includes(type))
      .map(benefit => ({
        ...benefit
      }));
      return result
    },
    plansByType: state => (type) => {
      let result = state.plans.filter(plan => plan.planType === type);
      return result;
    },
    activePlansByType: (state, getters) => type => {
      let result = []
      getters.plansByType(type).forEach((plan) => {
        if (plan.isActive) {
          result.push(plan)
        }
      })
      if(debug) {console.log("Active plans by type = ", result);}
      return result
    },
    activeAddOns: (state) => () => {
      let result = []
      state.addOns.forEach((addOn) => {
        if(addOn.isActive) {
          result.push(addOn);
        }
      })
      return result;
    },
    getAddOnBenefits: (state) => (addOnId) => {
      const addOn = state.addOns.find(addOn => addOn.id === addOnId);
      if (!addOn) {
        return [];
      }
      let result = addOn.benefits.map(benefitId => 
        state.benefits.find(benefit => benefit.id === benefitId)
      ).filter(benefit => benefit != null);
      return result
    },
    getBenefitByID: (state) => (benefitID) => {
      //console.log("Entering getBenefitByID- inputID = ", benefitID);
      const benefit = state.benefits.find(benefit => benefit.id === benefitID);
      //console.log("Returned benefit = ", benefit);
      return benefit;
    },
    getActiveMonthlyDiscounts: (state) => () => {
      return state.monthlyDiscounts.filter(discount => discount.isActive);
    },
    getActiveTreatmentDiscounts: (state) => () => {
      return state.treatmentDiscounts.filter(discount => discount.isActive);
    },
    benefitUCRsAllFilled: (state) => () => {
      return state.benefits.every((benefit) => {
        if(state.essentialCodes.includes(benefit.code)) {
          return (benefit.UCR !== 0 && benefit.UCR !== null);
        }
        else {
          return true;
        }
      });
    },

    contactPageFilled: (state) => (mode) => {
      if (
        state.practiceName !== "" &&
        state.practiceEmail !== "" &&
        state.practiceWebsite !== "" &&
        (mode !== 'intake' ? state.landingPage !== "" : true) &&
        state.practiceAddress !== "" &&
        state.practiceCity !== "" &&
        state.practiceState !== "" &&
        state.practiceZip !== "" &&
        state.practicePhone  !== "" && state.practicePhone != 0 &&
        (!state.includeBrochures || (state.brochureStyle !== '' && state.brochureColor !== ''))
      ) {
        return true;
      }
      return false;
    },

    getHighestBenefitID: (state) => () => {
      return state.benefits[state.benefits.length - 1].id;
    },
    getActivePlans: (state) => () => {
      return state.plans.filter(plan => plan.isActive);
    },
    getDiscountByID: (state) => (type, id) => {
      let discounts = "";
      if(type === 'M') {
        discounts = state.monthlyDiscounts;
      }
      else {
        discounts = state.treatmentDiscounts;
      }
      return discounts.find(discount => discount.id === id);
    },
    getPlanTypeFromInt: (state) => (int) => {
      return state.planTypes[int];
    },
    getBenefitFrequency: (state) => (inputNum, planFrequency) => {
      if(inputNum === -12) {
        return 12
      }
      else if(inputNum === -1) {
        return planFrequency
      }
      else {
        return inputNum;
      }

    },
    getDefaultBenefitFrequency: (state) => (inputBenefit) => {
      if(state.annualCodes.includes(inputBenefit.code)) {
        return 12;
      }
      return -1;
    },
    existsCode: (state) => (codeToMatch) => {
      return state.benefits.some(benefit => benefit.code === codeToMatch);
    }
  },
  actions: {
    setIsFreshIntake({commit}, val) {
      commit('SET_IS_FRESH_INTAKE', val);
    },
    updatePracticeName({ commit }, name) {
      commit('SET_PRACTICE_NAME', name);
    },
    updatePracticeEmail({ commit }, email) {
      commit('SET_PRACTICE_EMAIL', email);
    },

    updateRewardsPoints({commit}, num) {
      commit('SET_REWARDS_POINTS', num);
    },

    updateReimbursementPercentage({commit}, percent) {
      commit('SET_REIMBURSEMENT_PERCENTAGE', percent);
    },

    togglePointsIncludedInPrice({commit}) {
      commit('TOGGLE_POINTS_INCLUDED_IN_PRICE')
    },

    setDMFee({commit}, fee) {
      commit('SET_DM_FEE', fee);
    },

    toggleIncludeDMFee({commit}) {
      commit('TOGGLE_INCLUDE_DM_FEE')
    },

    toggleIsPediatric({commit}) {
      commit('TOGGLE_IS_PEDIATRIC')
    },

    convertToPediatric({commit}) {
      commit('CONVERT_TO_PEDIATRIC')
    },

    toggleIncludeProcessingFee({commit}) {
      commit('TOGGLE_INCLUDE_PROCESSING_FEE')
    },

    updateEnrollmentFee({commit}, fee) {
      commit('SET_ENROLLMENT_FEE', fee);
    },

    updatePercentBreakageStore({commit}, breakage) {
      commit('UPDATE_PERCENT_BREAKAGE_STORE', breakage)
    },

    updateBenefitByIDStore({commit}, benefit) {
      commit('UPDATE_BENEFIT_BY_ID', benefit)
    },

    addBenefitStore({commit, state}, benefit) {
      let highestId = 0;
      if (state.benefits.length > 0) {
        highestId = state.benefits.reduce((max, benefit) => (benefit.id > max ? benefit.id : max), state.benefits[0].id);
      }
      if(highestId < state.defaultBenefitsLength) {
        highestId = state.defaultBenefitsLength;
      }
      const newId = highestId + 1;
      // Append the newId to the benefit object
      const newBenefit = benefit;
      newBenefit.id = newId
      commit('ADD_BENEFIT', benefit)
      return Promise.resolve(newId)
    },

    removeBenefitStore({commit}, inputBenefit) {
      commit('REMOVE_BENEFIT', inputBenefit);
    },

    updatePlan({ commit }, plan) {
      commit('UPDATE_PLAN', plan)
    },

    setMonthlyDiscounts({commit}, discounts) {
      commit('SET_MONTHLY_DISCOUNTS', discounts);
    },

    updateMonthlyDiscountByID({commit}, inputDiscount) {
      commit('UPDATE_MONTHLY_DISCOUNT_BY_ID', inputDiscount)
    },

    updateTreatmentDiscountByID({commit}, inputDiscount) {
      commit('UPDATE_TREATMENT_DISCOUNT_BY_ID', inputDiscount)
    },

    setTreatmentDiscounts({commit}, discounts) {
      commit('SET_TREATMENT_DISCOUNTS', discounts)
    },

    setAddOns({commit}, addOns) {
      commit('SET_ADD_ONS', addOns)
    },

    updateAddOnByID({commit}, inputAddOn) {
      commit('UPDATE_ADD_ON_BY_ID', inputAddOn)
    },

    addBenefitToAddOn({commit}, [benefitId, addOnId]) {
      commit('ADD_BENEFIT_TO_ADD_ON', {benefitId, addOnId})
    },

    removeBenefitFromAddOnStore({ commit }, { benefitId, addOnId }) {
      commit('REMOVE_BENEFIT_FROM_ADD_ON', { benefitId, addOnId });
    },

    setUCRssSet({commit}) {
      commit('SET_UCRS_SET')
    },

    setAdultPlanSet({commit}) {
      commit('SET_ADULT_PLAN_SET')
    },

    setChildPlanSet({commit}) {
      commit('SET_CHILD_PLAN_SET')
    },

    setUCRsDemoStore({commit}) {
      commit('SET_UCRS_DEMO')
    },

    setUCRsMainStore({commit}) {
      commit('SET_UCRS_MAIN')
    },

    setBenefitPricesAtUCRStore({commit}) {
      commit('SET_BENEFIT_PRICES_AT_UCR')
    },

    setBenefitPricesBelowUCRStore({commit}) {
      commit('SET_BENEFIT_PRICES_BELOW_UCR')
    },

    setNote({commit}, note) {
      commit('SET_NOTE', note)
    },

    setPracticeWebsite({commit}, site) {
      commit('SET_WEBSITE', site)
    },

    setLandingPage({commit}, landingPage) {
      commit('SET_LANDING_PAGE', landingPage)
    },

    setPracticeAddress({commit}, address) {
      commit('SET_ADDRESS', address)
    },

    setAddressLineTwo({commit}, address) {
      commit('SET_ADDRESS_LINE_TWO', address)
    },

    setPhoneNumber({commit}, number) {
      commit('SET_PHONE_NUMBER', number)
    },

    setIncludeBrochures({commit}) {
      commit('SET_INCLUDE_BROCHURES');
    },

    setBrochureStyle({commit}, style) {
      commit('SET_BROCHURE_STYLE', style)
    },

    setBrochureColor({commit}, color) {
      commit('SET_BROCHURE_COLOR', color)
    },

    setPracticeCity({commit}, city) {
      commit ('SET_PRACTICE_CITY', city)
    },

    updatePracticeZip({ commit }, zip) {
      commit('SET_PRACTICE_ZIP', zip);
    },

    setPlanTypeName({commit}, {id, name}) {
      //console.log("Entering setPlanTypeName- id = ", id, ", New name = ", name)
      commit('SET_PLAN_TYPE_NAME', {planTypeIndex: id, newName: name})
    },

    setPracticeState({commit}, inputState) {
      commit ('SET_PRACTICE_STATE', inputState)
    },

    setUneditedStateToCurrentState({commit}) {
      commit ('SET_UNEDITED_STATE_TO_CURRENT_STATE');
    },

    setEverything({commit}, plan) {
      commit('SET_EVERYTHING', plan);
    },

  }
});
