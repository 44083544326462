<template>
    <div id="window-1">
        <div class="top-text">
            <h1>Survey Submitted!</h1>
            <h3>Thank you for filling out our setup survey. We're looking forward to building your plans with you soon!</h3>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    
    data() {
        return {
            
        };
    },
    methods: {
        
    },
    computed: {
        
    },
        
  };
</script>

<style scoped>

#window-1 {
    width: 50%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 2px solid var(--color-dark-blue);
    border-radius: 20px;
    justify-content: space-around;
}

.top-text {
    color: var(--color-dark-blue);
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    font-size: 2.5em;
    margin: 0;
    margin-top: 0.75em;
}

h3 {
    width: 75%;
    text-align: center;
    font-weight: 400;
    color: var(--font-color-teal);
}
</style>